import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mq } from '@/utils/helpers'
import { mobile } from '@/utils/media'
import Typography from '@/components/common/Typography'
import GhostButton from '@/components/common/GhostButton'

const InterviewLead = props => (
  <Root>
    <Root_Content theme={props.theme}>
      <Title variant="h2">社員インタビュー</Title>
      <Body variant="body1">入社理由・会社の好きなところ・仕事内容など、数字だけではわからないウィザードの情報を、社員の声を通してお伝えします。</Body>
      <Button to="/recruit/interview/" component={Link}>さらに詳しく</Button>
    </Root_Content>
  </Root>
)

export default ThemeConsumer(InterviewLead)

const Root = styled.div`
`

const Root_Content = styled.div`
  padding: 64px 0;
  background: ${props => props.theme.scale[800]};
  text-align: center;
`

const Title = styled(Typography)`
  text-align: center;
`

const Body = styled(Typography)`
  margin: 24px auto 0;
  text-align: center;
  max-width: 624px;
  @media ${mq.and(mobile)} {
    padding: 0 16px;
  }
`

const Button = styled(GhostButton)`
  margin-top: 40px;
  width: 180px;
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`
