import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-recruit.png'
import MainMessage from '@/components/common/MainMessage'
import CompanyLead from '@/components/modules/Recruit/Company/Lead'
import StatisticsLead from '@/components/modules/Recruit/Statistics/Lead'
import InterviewLead from '@/components/modules/Recruit/Interview/Lead'
import Office from '@/components/modules/Recruit/Office'
import Entry from '@/components/modules/Recruit/Entry'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'

const RecruitIndexPage = props => (
  <Root>
    <SEO location={props.location} title="採用情報" description="株式会社ウィザードの採用情報ページです。会社紹介、新卒採用、中途採用などの情報を掲載しています。"/>
    <Head>
      <PageTitle src={imgTitle}>採用情報</PageTitle>
      <MainMessage
        title={(
          <Fragment>幸せのしくみを<br />考える会社</Fragment>
        )}
        body={(
          <Fragment>「会社は社員とその家族ためにある。」創業から変わらないウィザードの理念です。大切にしていることは人によって異なります。それぞれに合った自由な働き方の実現を目指し、ウィザードは挑戦し続けています。</Fragment>
        )}
        css={styles.MainMessage}
      />
    </Head>
    <Main>
      <Row css={styles.Company}><CompanyLead /></Row>
      <Row css={styles.Statistics}><StatisticsLead /></Row>
      <Row css={styles.Interview}><InterviewLead /></Row>
      <Row><Office /></Row>
      <Row css={styles.Entry}><Entry /></Row>
      <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
      <Row css={styles.Contact}><ContactLead /></Row>
    </Main>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  MainMessage: css`
    br {
      display: none;
    }
    @media ${mq.and(mobile)} {
      br {
        display: initial;
      }
    }
  `,
  Company: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Statistics: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Interview: css`
    padding-top: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding-top: 0;
      margin-top: 24px;
    }
  `,
  Entry: css`
    padding-top: 0;
    margin-top: 64px;
    @media ${mq.and(mobile)} {
      padding-top: 0;
      margin-top: 32px;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(RecruitIndexPage)
