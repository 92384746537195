import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'

const RecruitOfficeCard = ({
  theme,
  area,
  headquarter,
  postalCode,
  address,
  tel,
  fax,
  access,
  map,
  ...other
}) => (
  <Root theme={theme} {...other}>
    <Map src={map} />
    <Main>
      <Name variant="h4" component="h4">{headquarter ? `本社（${area}）` : area}オフィス</Name>
      <Other>
        <Text variant="body3">〒{postalCode} {address}</Text>
        <Text variant="body3">TEL：{tel}　FAX：{fax}</Text>
        <Text variant="body3" dangerouslySetInnerHTML={{ __html: access }} />
      </Other>
    </Main>
  </Root>
)

const Root = styled.div`
  color: ${props => props.theme.text.contrast};
  background-color: ${props => props.theme.background.contrast};
`

const Map = styled.iframe`
  width: 100%;
  height: 326px;
`

const Main = styled.div`
  padding: 24px 64px 32px;
  @media ${mq.and(tabletL)} {
    padding: 24px 32px 32px;
  }
  @media ${mq.and(tabletS)} {
    padding: 20px 32px 32px;
  }
  @media ${mq.and(mobile)} {
    padding: 20px 16px 32px;
  }
`

const Name = styled(Typography)``

const Other = styled.div`
  margin-top: 16px;
`

const Text = styled(Typography)``

export default ThemeConsumer(RecruitOfficeCard)
